import React, { useState } from 'react';
import './AddPainting.css';

import { MDBAlert, MDBBtn } from 'mdb-react-ui-kit';

// Services
import { ApiService } from '../../services/Api-Service';

// Components
import TextInput from '../../components/atoms/inputs/textInput/TextInput';
import CanvasPicker from '../../components/atoms/canvasPicker/CanvasPicker';
import SizePicker from '../../components/atoms/sizePicker/SizePicker';
import ColorSchemePicker from '../../components/atoms/colorSchemePicker/ColorSchemePicker';
import ImageSelector from '../../components/molecules/imageSelector/ImageSelector';

// Types
import { CanvasType } from '../../types/image/CanvasType';
import { SizeType } from '../../types/image/SizeType';
import { ColorScheme } from '../../types/image/ColorScheme';
import { AddImageContentInput } from '../../types/image/AddImageContentInput';
import { useNavigate } from 'react-router-dom';
import { AddImageContentResult } from '../../types/image/AddImageContentResult';
import LoadingScreen from '../../components/atoms/loadingScreen/LoadingScreen';

type Props = {
  triggerPaintingSaved: () => void;
};

const AddPainting: React.FC<Props> = ({ triggerPaintingSaved }: Props) => {
  const navigate = useNavigate();
  const [nameLatin, setNameLatin] = useState('');
  const [name, setName] = useState('');
  const [canvas, setCanvas] = useState<CanvasType>(CanvasType.ACRYLIC);
  const [size, setSize] = useState<SizeType>(SizeType.THIRTY);
  const [color, setColor] = useState<ColorScheme>(ColorScheme.NONE);

  const [nameLatinError, setNameLatinError] = useState('');
  const [nameError, setNameError] = useState('');
  const [imageError, setImageError] = useState('');

  const [formData, setFormData] = useState<FormData>(new FormData());
  const [imageText, setImageText] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [showError, setShowError] = useState(false);

  const triggerUpload = () => {
    const isValid = checkForInputError();
    if (isValid) {
      triggerUploadContent();
    }
  };

  const checkForInputError = () => {
    let isValid = true;

    if (formData.getAll('image').length === 0) {
      setImageError('Please set an image');
      isValid = false;
    }

    if (nameLatin === '') {
      setNameLatinError('Please enter a name');
      isValid = false;
    }

    if (name === '') {
      setNameError('Please enter a name');
      isValid = false;
    }

    return isValid;
  };

  const triggerUploadContent = async () => {
    setIsLoading(true);
    const inputObj: AddImageContentInput = {
      nameLatin: nameLatin,
      name: name,
      canvas: canvas,
      size: size,
      color: color,
    };

    const res: AddImageContentResult | null =
      await ApiService.uploadPaintingContent(inputObj);

    if (res) {
      triggerUploadImage(res.id);
    } else {
      triggerError();
      setIsLoading(false);
    }
  };

  const triggerUploadImage = async (id: string) => {
    // Add new profile image
    const res: boolean = await ApiService.uploadPaintingImage(id, formData);
    setIsLoading(false);
    if (res) {
      // TODO: navigate and success alert
      navigate('/');
      triggerPaintingSaved();
    } else {
      triggerError();
    }
  };

  const onChange = (e: any) => {
    switch (e.target.name) {
      case 'nameLatin': {
        setNameLatin(e.target.value);
        setNameLatinError('');
        break;
      }
      case 'name': {
        setName(e.target.value);
        setNameError('');
        break;
      }
    }
  };

  const changeCanvas = (newCanvas: CanvasType) => {
    setCanvas(newCanvas);
  };

  const changeSize = (newSize: SizeType) => {
    setSize(newSize);
  };

  const changeColor = (newColor: ColorScheme) => {
    setColor(newColor);
  };

  const triggerError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 3000);
  };

  return (
    <div className='AddPainting'>
      <div className='AddPaintingInner'>
        <div className='header-container'>
          <span>Add Painting</span>
        </div>
        <ImageSelector
          existingImage=''
          formData={formData}
          imageText={imageText}
          className='input-argument'
          setFormData={setFormData}
          setImageText={setImageText}
          setImageError={setImageError}
        />
        {imageError !== '' ? (
          <div className='image-error-container'>
            <span>{imageError}</span>
          </div>
        ) : null}
        <TextInput
          value={nameLatin}
          label='Name in Latin'
          name='nameLatin'
          onChange={onChange}
          errorMessage={nameLatinError}
          className='input-argument'
        />
        <TextInput
          value={name}
          label='Name'
          name='name'
          onChange={onChange}
          errorMessage={nameError}
          className='input-argument'
        />
        <CanvasPicker
          value={canvas}
          changeSelection={changeCanvas}
          className='input-argument'
        />
        <SizePicker
          value={size}
          changeSelection={changeSize}
          className='input-argument'
        />
        <ColorSchemePicker
          value={color}
          changeSelection={changeColor}
          className='input-argument'
        />
        <div className='button-container'>
          <MDBBtn onClick={triggerUpload}>Upload</MDBBtn>
        </div>
      </div>
      <LoadingScreen isLoading={isLoading} />
      <MDBAlert
        color='danger'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showError}
      >
        <b>Something went wrong!</b>
        <br />
        Please try again.
      </MDBAlert>
    </div>
  );
};

export default AddPainting;
