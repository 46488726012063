import React, { useCallback, useEffect, useState } from 'react';
import './Explore.css';

// Libraries
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MDBRow, MDBCol, MDBBtn, MDBAlert } from 'mdb-react-ui-kit';

// Components
import SearchBar from '../../components/atoms/searchBar/SearchBar';
import Paging from '../../components/atoms/paging/Paging';
import ExploreSort from '../../components/molecules/explore/exploreSort/ExploreSort';

// Services
import { ApiService } from '../../services/Api-Service';

// Types
import { ExploreImagesResult } from '../../types/explore/ExploreImagesResult';
import { ExploreImage } from '../../types/explore/ExploreImage';
import SimpleLoadingScreen from '../../components/atoms/simpleLoadingScreen/SimpleLoadingScreen';
import { ColorScheme } from '../../types/image/ColorScheme';
import ExploreCard from '../../components/organisms/explore/exploreCard/ExploreCard';
import LoadingScreen from '../../components/atoms/loadingScreen/LoadingScreen';

const Explore: React.FC = () => {
  //   let { urlSearch, urlPage } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedSort, setSelectedSort] = useState(ColorScheme.NONE);

  const [pagesCount, setPagesCount] = useState(1);
  const [paintings, setPaintings] = useState<ExploreImage[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState(true);

  const [showDeletionSuccess, setShowDeletionSuccess] = useState(false);
  const [showDeletionError, setShowDeletionError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const searchPaintings = useCallback(
    async (searchString: string, page: number, sort: number) => {
      let encodedString = encodeURI(searchString);
      setIsSearchLoading(true);
      const res: ExploreImagesResult | null = await ApiService.searchPaintings(
        encodedString,
        page,
        sort
      );

      if (res != null) {
        console.log(res);
        setPaintings(res.paintings);
        setSelectedPage(res.page);
        setPagesCount(res.pages);
      }

      setIsSearchLoading(false);
    },
    []
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  useEffect(() => {
    // get params
    let urlSearch = searchParams.get('search');
    let urlPage = searchParams.get('page');
    let urlSort = searchParams.get('sort');

    // Convert value or set as undefined
    let urlCheckedSearch: string | undefined = urlSearch
      ? urlSearch
      : undefined;
    let urlCheckedPage: number | undefined =
      urlPage != null && parseInt(urlPage) ? parseInt(urlPage) : undefined;
    let urlCheckedSort: number | undefined =
      urlSort != null &&
      parseInt(urlSort) &&
      parseInt(urlSort) <= 7 &&
      parseInt(urlSort) >= 0
        ? parseInt(urlSort)
        : undefined;

    // set selected States
    setSelectedSearch(urlCheckedSearch ? urlCheckedSearch : '');
    setSearch(urlCheckedSearch ? urlCheckedSearch : '');
    setSelectedPage(urlCheckedPage ? urlCheckedPage : 1);
    setSelectedSort(urlCheckedSort ? urlCheckedSort : 0);

    searchPaintings(
      urlCheckedSearch ? urlCheckedSearch : '',
      urlCheckedPage ? urlCheckedPage : 1,
      urlCheckedSort ? urlCheckedSort : 0
    );
  }, [searchParams, navigate, searchPaintings]);

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const changeSearch = (newSearch: string) => {
    setSelectedSearch(newSearch);

    // fix url
    navigate(
      '/explore' +
        (newSearch !== '' || selectedPage !== 1 || selectedSort !== 0
          ? '?'
          : '') +
        (newSearch !== '' ? 'search=' + newSearch : '') +
        (newSearch !== '' && selectedPage !== 1 ? '&' : '') +
        (selectedPage !== 1 ? 'page=' + selectedPage : '') +
        ((newSearch !== '' || selectedPage !== 1) && selectedSort !== 0
          ? '&'
          : '') +
        (selectedSort !== 0 ? 'sort=' + selectedSort : '')
    );
  };

  const changePage = (newPage: number) => {
    // if (newPage !== selectedPage) {
    setSelectedPage(newPage);

    // fix url
    navigate(
      '/explore' +
        (selectedSearch !== '' || newPage !== 1 || selectedSort !== 0
          ? '?'
          : '') +
        (selectedSearch !== '' ? 'search=' + selectedSearch : '') +
        (selectedSearch !== '' && newPage !== 1 ? '&' : '') +
        (newPage !== 1 ? 'page=' + newPage : '') +
        ((selectedSearch !== '' || newPage !== 1) && selectedSort !== 0
          ? '&'
          : '') +
        (selectedSort !== 0 ? 'sort=' + selectedSort : '')
    );
    // }
  };

  const changeSort = (newSort: number) => {
    setSelectedSort(newSort);

    // fix url
    navigate(
      '/explore' +
        (selectedSearch !== '' || selectedPage !== 1 || newSort !== 0
          ? '?'
          : '') +
        (selectedSearch !== '' ? 'search=' + selectedSearch : '') +
        (selectedSearch !== '' && selectedPage !== 1 ? '&' : '') +
        (selectedPage !== 1 ? 'page=' + selectedPage : '') +
        ((selectedSearch !== '' || selectedPage !== 1) && newSort !== 0
          ? '&'
          : '') +
        (newSort !== 0 ? 'sort=' + newSort : '')
    );
  };

  const clear = () => {
    navigate('/explore');
  };

  const deletePainting = async (id: string) => {
    setIsLoading(true);
    let res: boolean = await ApiService.deletePainting(id);
    setIsLoading(false);
    if (res) {
      triggerDeletionSuccessAlert();
      searchPaintings(selectedSearch, selectedPage, selectedSort);
      return true;
    } else {
      triggerDeletionErrorAlert();
      return false;
    }
  };

  const triggerDeletionSuccessAlert = () => {
    setShowDeletionSuccess(true);
    setTimeout(() => {
      setShowDeletionSuccess(false);
    }, 3000);
  };

  const triggerDeletionErrorAlert = () => {
    setShowDeletionError(true);
    setTimeout(() => {
      setShowDeletionError(false);
    }, 3000);
  };

  return (
    <div className='Explore'>
      <SearchBar
        value={search}
        label='Search'
        onChange={onSearchChange}
        triggerSearch={changeSearch}
      />
      <MDBRow className='sort-container'>
        <MDBCol md='1' className='clear-container'>
          <MDBBtn onClick={clear} className='custom-primary-light-button'>
            Clear
          </MDBBtn>
        </MDBCol>
        <MDBCol md='3'></MDBCol>
        <MDBCol md='2'></MDBCol>
        <MDBCol md='3'></MDBCol>
        <MDBCol md='3'>
          <ExploreSort
            changeSort={changeSort}
            value={selectedSort}
            className='sort-input'
          />
        </MDBCol>
      </MDBRow>
      {!isSearchLoading ? (
        paintings.length !== 0 ? (
          <div className='custom-row'>
            {paintings.map((tempPainting: ExploreImage) => (
              <div className='custom-col' key={tempPainting.id}>
                <ExploreCard
                  image={tempPainting}
                  deletePainting={deletePainting}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className='no-contracts-container'>
            <span>No paintings</span>
          </div>
        )
      ) : (
        <SimpleLoadingScreen />
      )}

      {paintings.length !== 0 ? (
        <Paging
          pagesCount={pagesCount}
          selectedPage={selectedPage}
          className='paging-input'
          changePage={changePage}
        />
      ) : null}
      <LoadingScreen isLoading={isLoading} />
      <MDBAlert
        color='success'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showDeletionSuccess}
        // className='custom-alert-center-position'
      >
        <b>Contract deleted!</b>
      </MDBAlert>
      <MDBAlert
        color='danger'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showDeletionError}
        // className='custom-alert-center-position'
      >
        <i className='fas fa-times-circle me-3'></i>Deletion failed.
      </MDBAlert>
    </div>
  );
};

export default Explore;
