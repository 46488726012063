import React, { useState } from 'react';
import './Login.css';

import { MDBAlert, MDBBtn } from 'mdb-react-ui-kit';

// Services
import { ApiService } from '../../services/Api-Service';

// Components
import TextInput from '../../components/atoms/inputs/textInput/TextInput';
import PasswordInput from '../../components/atoms/inputs/passwordInput/PasswordInput';

// Types
import { LoginInput } from '../../types/login/LoginInput';
import { User } from '../../types/user/User';
import LoadingScreen from '../../components/atoms/loadingScreen/LoadingScreen';

type Props = {
  setUser: (x: User | null) => void;
};

declare const window: any;

const Login: React.FC<Props> = ({ setUser }: Props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showWrongError, setWrongError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const triggerWrongError = () => {
    setWrongError(true);
    setTimeout(() => {
      setWrongError(false);
    }, 3000);
  };

  const onChange = (e: any) => {
    switch (e.target.name) {
      case 'username': {
        setUsername(e.target.value);
        setUsernameError(false);
        break;
      }
      case 'password': {
        setPassword(e.target.value);
        setPasswordError(false);
        break;
      }
    }
  };

  const triggerLogin = () => {
    const isValid = checkInputs();
    if (isValid) {
      doLogin();
    }
  };

  const checkInputs = () => {
    let isValid = true;

    if (username === '') {
      setUsernameError(true);
      isValid = false;
    }

    if (password === '') {
      setPasswordError(true);
      isValid = false;
    }

    return isValid;
  };

  const doLogin = async () => {
    setIsLoading(true);

    const loginInput: LoginInput = {
      username: username,
      password: password,
    };

    const res: User | null = await ApiService.login(loginInput);

    setIsLoading(false);

    if (res) {
      setUser(res);
      window.location.reload(false);
    } else {
      triggerWrongError();
    }
  };
  return (
    <div className='Login'>
      <h1>Login</h1>
      <TextInput
        value={username}
        name='username'
        label='Username'
        onChange={onChange}
        errorMessage={usernameError ? 'Please enter a username.' : ''}
        className='input-element'
      />
      <PasswordInput
        value={password}
        name='password'
        label='Password'
        onChange={onChange}
        errorMessage={passwordError ? 'Please enter a password.' : ''}
        className='input-element'
      />
      <MDBBtn onClick={triggerLogin}>Login</MDBBtn>
      <LoadingScreen isLoading={isLoading} />
      <MDBAlert
        color='danger'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showWrongError}
      >
        <b>Something went wrong!</b>
        <br />
        Please try again.
      </MDBAlert>
    </div>
  );
};

export default Login;
