import React, { useRef, useState } from 'react';
import './CreateEmail.css';

import EmailEditor, { EditorRef } from 'react-email-editor';
import { MDBAlert, MDBBtn } from 'mdb-react-ui-kit';
import TextInput from '../../components/atoms/inputs/textInput/TextInput';
import { AddEmailTemplateInput } from '../../types/emailTemplate/AddEmailTemplateInput';
import { ApiService } from '../../services/Api-Service';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/atoms/loadingScreen/LoadingScreen';

const CreateEmail: React.FC = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [nameError, setNameError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [wrongError, setWrongError] = useState(false);
  const emailEditorRef = useRef<EditorRef>(null);

  const triggerSave = () => {
    const isValid = checkForInputError();
    if (isValid) {
      saveTemplate();
    }
  };

  const checkForInputError = () => {
    let isValid = true;
    if (name === '') {
      setNameError('Please enter a name.');
      isValid = false;
    }

    if (subject === '') {
      setSubjectError('Please enter a subject.');
      isValid = false;
    }

    return isValid;
  };

  const saveTemplate = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml(async (data) => {
      const { design, html } = data;
      setIsLoading(true);

      const inputObj: AddEmailTemplateInput = {
        name: name,
        subject: subject,
        html: html,
        design: design,
      };

      const res: boolean = await ApiService.addEmailTemplate(inputObj);

      setIsLoading(false);

      if (res) {
        navigate('/mail-explore');
      } else {
        triggerWrongError();
      }
    });
  };

  const triggerWrongError = () => {
    setWrongError(true);
    setTimeout(() => {
      setWrongError(false);
    }, 3000);
  };

  const onChange = (e: any) => {
    switch (e.target.name) {
      case 'name': {
        setName(e.target.value);
        setNameError('');
        break;
      }
      case 'subject': {
        setSubject(e.target.value);
        setSubjectError('');
        break;
      }
    }
  };
  return (
    <div className='CreateEmail'>
      <div className='pb15'>
        <TextInput
          value={name}
          label='Name'
          name='name'
          onChange={onChange}
          errorMessage={nameError}
        />
      </div>
      <div className='pb15'>
        <TextInput
          value={subject}
          label='Subject'
          name='subject'
          onChange={onChange}
          errorMessage={subjectError}
        />
      </div>
      <div className='mb15'>
        <MDBBtn onClick={triggerSave}>Save</MDBBtn>
      </div>
      <EmailEditor ref={emailEditorRef} />
      <MDBAlert color='info' show={true}>
        <i className='fa-regular fa-circle me-3'></i>{' '}
        <b>Individual Unsubscribe Link:</b> --xx--Unsubscribe_Link--xx--
        <br />
        <i className='fa-regular fa-circle me-3'></i>{' '}
        <b>Individual User Name:</b> --xx--User_Name--xx--
      </MDBAlert>
      <LoadingScreen isLoading={isLoading} />
      <MDBAlert
        color='danger'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={wrongError}
      >
        <i className='fas fa-times-circle me-3'></i>Something went wrong.
      </MDBAlert>
    </div>
  );
};

export default CreateEmail;
