export const getDateString = (timestamp: number) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return getWithZero(day) + '/' + getWithZero(month + 1) + '/' + year;
};

const getWithZero = (value: number) => {
  if (value < 10) {
    return '0' + value;
  } else {
    return value;
  }
};
