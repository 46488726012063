import React from 'react';
import './SearchBar.css';
import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';

// Icons
import { BiSearch } from 'react-icons/bi';

type Props = {
  value: string;
  label: string;
  className?: string;
  onChange: (e: any) => void;
  triggerSearch: (x: string) => void;
};

const SearchBar: React.FC<Props> = ({
  value,
  label,
  className,
  onChange,
  triggerSearch,
}) => {
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      // Trigger search
      triggerSearch(value);
    }
  };
  return (
    <div className={`SearchBar ${className}`}>
      <MDBInput
        value={value}
        name='search'
        onChange={onChange}
        onKeyDown={handleKeyDown}
        required
        label={label}
      />
      <MDBBtn
        className='custom-primary-button'
        onClick={() => triggerSearch(value)}
      >
        <BiSearch />
      </MDBBtn>
    </div>
  );
};

export default SearchBar;
