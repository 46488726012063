import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import Cookies from 'universal-cookie';

// Services
import { ApiService } from './services/Api-Service';

// Components
import SimpleLoadingScreen from './components/atoms/simpleLoadingScreen/SimpleLoadingScreen';
import Login from './pages/login/Login';
import Home from './pages/home/Home';

// Types
import { User } from './types/user/User';
import { Route, Routes } from 'react-router-dom';
import Navigation from './components/organisms/navigation/Navigation';
import AddPainting from './pages/addPainting/AddPainting';
import { MDBAlert } from 'mdb-react-ui-kit';
import Explore from './pages/explore/Explore';
import EditPainting from './pages/editPainting/EditPainting';
import CreateEmail from './pages/createEmail/CreateEmail';
import ExploreMails from './pages/exploreMails/ExploreMails';
import EditMail from './pages/editMail/EditMail';
import NewsletterUserInfo from './pages/newsletterUserInfo/NewsletterUserInfo';

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const [showPaintingSaved, setShowPaintingSaved] = useState(false);

  const checkIfLoggedIn = useCallback(async () => {
    const cookies = new Cookies();
    const tempRefreshToken = cookies.get('adminRefreshToken');
    const tempUser = cookies.get('adminUser');
    if (tempRefreshToken != null && tempUser != null) {
      setUser(tempUser);
      const tempNewUser: User | null = await ApiService.initialReauthentication(
        tempRefreshToken
      );
      setUser(tempNewUser);
      setIsInitialLoading(false);
    } else {
      setIsInitialLoading(false);
    }
  }, []);

  useEffect(() => {
    checkIfLoggedIn();
  }, [checkIfLoggedIn]);

  const triggerPaintingSaved = () => {
    setShowPaintingSaved(true);
    setTimeout(() => {
      setShowPaintingSaved(false);
    }, 3000);
  };
  return (
    <div className='App'>
      {!isInitialLoading ? (
        <div className='AppContent'>
          <div className='AppContentInner'>
            {user != null ? (
              <>
                <Navigation />
                <Routes>
                  <Route path='' element={<Home />} />
                  <Route
                    path='upload-painting'
                    element={
                      <AddPainting
                        triggerPaintingSaved={triggerPaintingSaved}
                      />
                    }
                  />
                  <Route
                    path='edit-painting/:id'
                    element={
                      <EditPainting
                        triggerPaintingSaved={triggerPaintingSaved}
                      />
                    }
                  />
                  <Route path='explore' element={<Explore />} />
                  <Route path='mail-explore' element={<ExploreMails />} />
                  <Route path='create-mail' element={<CreateEmail />} />
                  <Route path='edit-mail/:id' element={<EditMail />} />
                  <Route
                    path='newsletter-subscribers'
                    element={<NewsletterUserInfo />}
                  />
                  <Route path='*' element={<Home />} />
                </Routes>
              </>
            ) : (
              <Login setUser={setUser} />
            )}
          </div>
        </div>
      ) : (
        <SimpleLoadingScreen />
      )}
      <MDBAlert
        color='success'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showPaintingSaved}
      >
        <b>Painting saved!</b>
      </MDBAlert>
    </div>
  );
};

export default App;
