import React, { useCallback, useEffect, useRef, useState } from 'react';
import './EditMail.css';

import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import { MDBAlert, MDBBtn } from 'mdb-react-ui-kit';
import TextInput from '../../components/atoms/inputs/textInput/TextInput';
import { AddEmailTemplateInput } from '../../types/emailTemplate/AddEmailTemplateInput';
import { ApiService } from '../../services/Api-Service';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../components/atoms/loadingScreen/LoadingScreen';
import { EditEmailTemplate } from '../../types/emailTemplate/EditEmailTemplate';
import SimpleLoadingScreen from '../../components/atoms/simpleLoadingScreen/SimpleLoadingScreen';

const EditMail: React.FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [nameError, setNameError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [wrongError, setWrongError] = useState(false);
  const emailEditorRef = useRef<EditorRef>(null);

  const [template, setTemplate] = useState<EditEmailTemplate | null>(null);

  const [isPageLoading, setIsPageLoading] = useState(true);

  const getTemplate = useCallback(async () => {
    if (id) {
      const res: EditEmailTemplate | null = await ApiService.getEditTemplate(
        id
      );

      if (res) {
        setName(res.name);
        setSubject(res.subject);
        setTemplate(res);
      }

      setIsPageLoading(false);
    } else {
      setIsPageLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (template == null) {
      getTemplate();
    } else {
      setIsPageLoading(false);
    }
  }, [template, getTemplate]);

  const triggerSave = () => {
    const isValid = checkForInputError();
    if (isValid) {
      saveTemplate();
    }
  };

  const checkForInputError = () => {
    let isValid = true;
    if (name === '') {
      setNameError('Please enter a name.');
      isValid = false;
    }

    if (subject === '') {
      setSubjectError('Please enter a subject.');
      isValid = false;
    }

    return isValid;
  };

  const saveTemplate = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml(async (data) => {
      const { design, html } = data;
      setIsLoading(true);

      const inputObj: AddEmailTemplateInput = {
        name: name,
        subject: subject,
        html: html,
        design: design,
      };

      const res: boolean = await ApiService.updateEmailTemplate(
        template!.id,
        inputObj
      );

      setIsLoading(false);

      if (res) {
        navigate('/mail-explore');
      } else {
        triggerWrongError();
      }
    });
  };

  const triggerWrongError = () => {
    setWrongError(true);
    setTimeout(() => {
      setWrongError(false);
    }, 3000);
  };

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    unlayer.loadDesign(template?.design);
  };

  const onChange = (e: any) => {
    switch (e.target.name) {
      case 'name': {
        setName(e.target.value);
        setNameError('');
        break;
      }
      case 'subject': {
        setSubject(e.target.value);
        setSubjectError('');
        break;
      }
    }
  };
  return (
    <div className='EditMail'>
      {!isPageLoading ? (
        template != null ? (
          <>
            <div className='pb15'>
              <TextInput
                value={name}
                label='Name'
                name='name'
                onChange={onChange}
                errorMessage={nameError}
              />
            </div>
            <div className='pb15'>
              <TextInput
                value={subject}
                label='Subject'
                name='subject'
                onChange={onChange}
                errorMessage={subjectError}
              />
            </div>
            <div className='mb15'>
              <MDBBtn onClick={triggerSave}>Save</MDBBtn>
            </div>
            <EmailEditor ref={emailEditorRef} onReady={onReady} />
            <MDBAlert color='info' show={true}>
              <i className='fa-regular fa-circle me-3'></i>{' '}
              <b>Individual Unsubscribe Link:</b> --xx--Unsubscribe_Link--xx--
              <br />
              <i className='fa-regular fa-circle me-3'></i>{' '}
              <b>Individual User Name:</b> --xx--User_Name--xx--
            </MDBAlert>
          </>
        ) : (
          <span>Mail not found.</span>
        )
      ) : (
        <SimpleLoadingScreen />
      )}
      <LoadingScreen isLoading={isLoading} />
      <MDBAlert
        color='danger'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={wrongError}
      >
        <i className='fas fa-times-circle me-3'></i>Something went wrong.
      </MDBAlert>
    </div>
  );
};

export default EditMail;
