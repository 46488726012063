import React from 'react';
import './Paging.css';
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from 'mdb-react-ui-kit';

type Props = {
  pagesCount: number;
  selectedPage: number;
  className?: string;
  changePage: (x: number) => void;
};

const Paging: React.FC<Props> = ({
  pagesCount,
  selectedPage,
  className,
  changePage,
}: Props) => {
  const getPagingElements = () => {
    if (pagesCount > 5) {
      // Five cases: element is one, element is two, element is middle, element is four, element is five
      return new Array(5).fill(0).map((value: number, index: number) => {
        let tempPage = selectedPage + index - 2;
        if (selectedPage - 1 === 0) {
          tempPage = index + 1;
        } else if (selectedPage - 1 === 1) {
          tempPage = index + 1;
        } else if (pagesCount - selectedPage === 0) {
          tempPage = selectedPage - 4 + index;
        } else if (pagesCount - selectedPage === 1) {
          tempPage = selectedPage - 4 + (index + 1);
        }
        return (
          <MDBPaginationItem
            key={tempPage}
            className='paging-item'
            onClick={() => changePage(tempPage)}
          >
            <MDBPaginationLink
              className={selectedPage === tempPage ? 'active-page' : ''}
            >
              {tempPage}
            </MDBPaginationLink>
          </MDBPaginationItem>
        );
      });
    } else {
      return new Array(pagesCount)
        .fill(0)
        .map((value: number, index: number) => (
          <MDBPaginationItem
            key={index + 1}
            className='paging-item'
            onClick={() => changePage(index + 1)}
          >
            <MDBPaginationLink
              className={selectedPage === index + 1 ? 'active-page' : ''}
            >
              {index + 1}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ));
    }
  };

  return (
    <div className={`Paging ${className}`}>
      <MDBPagination className='mb-0'>
        <MDBPaginationItem
          disabled={selectedPage === 1}
          onClick={() => {
            if (selectedPage - 1 >= 1) {
              changePage(selectedPage - 1);
            }
          }}
          className={
            selectedPage === 1 ? 'disabled-paging-item' : 'paging-item'
          }
        >
          <MDBPaginationLink
            className={selectedPage === 1 ? 'page-disabled' : ''}
          >
            <span aria-hidden='true'>«</span>
          </MDBPaginationLink>
        </MDBPaginationItem>
        {getPagingElements()}
        <MDBPaginationItem
          disabled={selectedPage === pagesCount}
          onClick={() => {
            if (selectedPage + 1 <= pagesCount) {
              changePage(selectedPage + 1);
            }
          }}
          className={
            selectedPage === pagesCount ? 'disabled-paging-item' : 'paging-item'
          }
        >
          <MDBPaginationLink
            className={selectedPage === pagesCount ? 'page-disabled' : ''}
          >
            <span aria-hidden='true'>»</span>
          </MDBPaginationLink>
        </MDBPaginationItem>
      </MDBPagination>
    </div>
  );
};

export default Paging;
