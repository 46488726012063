import React from 'react';
import './PasswordInput.css';
import { MDBInput, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';

type Props = {
  value: string;
  name: string;
  label: string;
  errorMessage?: string;
  className?: string;
  onChange: (e: any) => void;
};

const PasswordInput: React.FC<Props> = ({
  value,
  name,
  label,
  errorMessage,
  className,
  onChange,
}) => {
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      // Trigger search
      setTimeout(() => {
        removeWasValidated();
      }, 10);
    }
  };

  const removeWasValidated = () => {
    var element = document.querySelector('.was-validated');
    if (element) {
      element.classList.remove('was-validated');
    }
    console.log('hola');
  };

  return (
    <div className={`PasswordInput ${className}`}>
      <MDBValidation>
        <MDBValidationItem className='input' feedback={errorMessage} invalid>
          <MDBInput
            value={value}
            type='password'
            autoComplete='on'
            name={name}
            onChange={onChange}
            required
            label={label}
            className={
              errorMessage !== '' && errorMessage !== undefined
                ? 'is-invalid'
                : ''
            }
            onKeyDown={handleKeyDown}
          />
        </MDBValidationItem>
      </MDBValidation>
    </div>
  );
};

export default PasswordInput;
