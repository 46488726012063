import React, { useState } from 'react';
import './EditPaintingContent.css';
import { EditImage } from '../../../../types/image/EditImage';
import ImageSelector from '../../../molecules/imageSelector/ImageSelector';
import TextInput from '../../../atoms/inputs/textInput/TextInput';
import CanvasPicker from '../../../atoms/canvasPicker/CanvasPicker';
import SizePicker from '../../../atoms/sizePicker/SizePicker';
import ColorSchemePicker from '../../../atoms/colorSchemePicker/ColorSchemePicker';
import { MDBAlert, MDBBtn, MDBDatepicker } from 'mdb-react-ui-kit';
import LoadingScreen from '../../../atoms/loadingScreen/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { CanvasType } from '../../../../types/image/CanvasType';
import { SizeType } from '../../../../types/image/SizeType';
import { ColorScheme } from '../../../../types/image/ColorScheme';
import { ImageStatus } from '../../../../types/image/ImageStatus';
import { ApiService } from '../../../../services/Api-Service';
import { EditImageContentInput } from '../../../../types/image/EdiImageContentInput';
import { getDateString } from '../../../../utils/getDateString';

type Props = {
  painting: EditImage;
  triggerPaintingSaved: () => void;
};

const EditPaintingContent: React.FC<Props> = ({
  painting,
  triggerPaintingSaved,
}: Props) => {
  const navigate = useNavigate();
  const [nameLatin, setNameLatin] = useState(painting.nameLatin);
  const [name, setName] = useState(painting.name);
  const [canvas, setCanvas] = useState<CanvasType>(painting.canvas);
  const [size, setSize] = useState<SizeType>(painting.size);
  const [color, setColor] = useState<ColorScheme>(painting.color);
  const [timestamp, setTimestamp] = useState(painting.timestamp);

  const [nameLatinError, setNameLatinError] = useState('');
  const [nameError, setNameError] = useState('');
  const [imageError, setImageError] = useState('');

  const [formData, setFormData] = useState<FormData>(new FormData());
  const [imageText, setImageText] = useState('');
  const [imageStatus, setImageStatus] = useState<ImageStatus>(ImageStatus.SET);

  const [isLoading, setIsLoading] = useState(false);

  const [showError, setShowError] = useState(false);

  const triggerUpload = () => {
    const isValid = checkForInputError();
    if (isValid) {
      triggerUploadContent();
    }
  };

  const checkForInputError = () => {
    let isValid = true;

    if (imageStatus === ImageStatus.EMPTY) {
      setImageError('Please set an image');
      isValid = false;
    }

    if (nameLatin === '') {
      setNameLatinError('Please enter a name');
      isValid = false;
    }

    if (name === '') {
      setNameError('Please enter a name');
      isValid = false;
    }

    return isValid;
  };

  const triggerUploadContent = async () => {
    setIsLoading(true);
    const inputObj: EditImageContentInput = {
      nameLatin: nameLatin,
      name: name,
      canvas: canvas,
      size: size,
      color: color,
      timestamp: timestamp,
    };

    console.log(inputObj);

    const res: boolean = await ApiService.updatePaintingContent(
      painting.id,
      inputObj
    );

    if (res) {
      if (imageStatus === ImageStatus.NEW) {
        triggerUploadImage(painting.id);
      } else {
        setIsLoading(false);
        triggerPaintingSaved();
        navigate('/');
      }
    } else {
      triggerError();
      setIsLoading(false);
    }
  };

  const triggerUploadImage = async (id: string) => {
    console.log(id);
    // Add new profile image
    const res: boolean = await ApiService.updatePaintingImage(id, formData);
    setIsLoading(false);
    if (res) {
      triggerPaintingSaved();
      navigate('/');
    } else {
      triggerError();
    }
  };

  const onChange = (e: any) => {
    switch (e.target.name) {
      case 'nameLatin': {
        setNameLatin(e.target.value);
        setNameLatinError('');
        break;
      }
      case 'name': {
        setName(e.target.value);
        setNameError('');
        break;
      }
    }
  };

  const onDateChange = (value: string, date: Date) => {
    const dateValues = value.split('/');
    const newDate = new Date(
      parseInt(dateValues[2]),
      parseInt(dateValues[1]) - 1,
      parseInt(dateValues[0])
    );
    setTimestamp(newDate.getTime());

    // console.log(new Date(value));
    // console.log(date);
    // console.log('hihi');
    // console.log(new Date(date.getTime()));

    // setTimestamp(date.getTime());
    // console.log('Value: - ' + new Date(value));
    // console.log('Date: - ' + date);
    // setTimestamp(new Date(value).getTime());
  };

  const changeCanvas = (newCanvas: CanvasType) => {
    setCanvas(newCanvas);
  };

  const changeSize = (newSize: SizeType) => {
    setSize(newSize);
  };

  const changeColor = (newColor: ColorScheme) => {
    setColor(newColor);
  };

  const triggerError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 3000);
  };
  return (
    <div className='EditPaintingContent'>
      <div className='EditPaintingContentInner'>
        <div className='header-container'>
          <span>Edit Painting</span>
        </div>
        <ImageSelector
          existingImage={painting.imageid}
          formData={formData}
          imageText={imageText}
          className='input-argument'
          setFormData={setFormData}
          setImageStatus={setImageStatus}
          setImageText={setImageText}
          setImageError={setImageError}
        />
        {imageError !== '' ? (
          <div className='image-error-container'>
            <span>{imageError}</span>
          </div>
        ) : null}
        <TextInput
          value={nameLatin}
          label='Name in Latin'
          name='nameLatin'
          onChange={onChange}
          errorMessage={nameLatinError}
          className='input-argument'
        />
        <TextInput
          value={name}
          label='Name'
          name='name'
          onChange={onChange}
          errorMessage={nameError}
          className='input-argument'
        />
        <CanvasPicker
          value={canvas}
          changeSelection={changeCanvas}
          className='input-argument'
        />
        <SizePicker
          value={size}
          changeSelection={changeSize}
          className='input-argument'
        />
        <ColorSchemePicker
          value={color}
          changeSelection={changeColor}
          className='input-argument'
        />
        <MDBDatepicker
          // value={getDateString(timestamp)}
          defaultValue={getDateString(timestamp)}
          onChange={onDateChange}
        />
        <div className='button-container'>
          <MDBBtn onClick={triggerUpload}>Upload</MDBBtn>
        </div>
      </div>
      <LoadingScreen isLoading={isLoading} />
      <MDBAlert
        color='danger'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showError}
      >
        <b>Something went wrong!</b>
        <br />
        Please try again.
      </MDBAlert>
    </div>
  );
};

export default EditPaintingContent;
