import React from 'react';
import './ExploreCard.css';

// Libraries
import { Link } from 'react-router-dom';
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from 'mdb-react-ui-kit';

import { AiTwotoneEdit } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BiSolidTrashAlt } from 'react-icons/bi';

// Types
import { ExploreImage } from '../../../../types/explore/ExploreImage';
import { SizeType } from '../../../../types/image/SizeType';
import { CanvasType } from '../../../../types/image/CanvasType';

type Props = {
  image: ExploreImage;
  className?: string;
  deletePainting: (x: string) => Promise<boolean>;
};

const ExploreCard: React.FC<Props> = ({
  image,
  className,
  deletePainting,
}: Props) => {
  const triggerContractDeletion = async () => {
    await deletePainting(image.id);
  };

  const getImageSize = () => {
    switch (image.size) {
      case SizeType.EIGHTY: {
        return 'percent100';
      }
      case SizeType.SIXTY: {
        return 'percent90';
      }
      case SizeType.FIFTY: {
        return 'percent85';
      }
      case SizeType.FOURTY: {
        return 'percent80';
      }
      case SizeType.THIRTY: {
        return 'percent80';
      }
      case SizeType.FIFTEEN: {
        return 'percent80';
      }
    }
  };

  const getCanvas = () => {
    switch (image.canvas) {
      case CanvasType.ACRYLIC: {
        return 'Acrylic on canvas';
      }
      case CanvasType.ACRYLICANDOILSTICK: {
        return 'Acrylic + oil stick on canvas';
      }
    }
  };

  const getSize = () => {
    switch (image.size) {
      case SizeType.FIFTEEN: {
        return '15x15x1.5 cm';
      }
      case SizeType.THIRTY: {
        return '30x30x4.5 cm';
      }
      case SizeType.FOURTY: {
        return '40x40x4.5 cm';
      }
      case SizeType.FIFTY: {
        return '50x50x4.5 cm';
      }
      case SizeType.SIXTY: {
        return '60x60x4.5 cm';
      }
      case SizeType.EIGHTY: {
        return '80x80x4.5 cm';
      }
    }
  };

  return (
    <div className={`ExploreCard ${className}`}>
      <div className='painting-container'>
        <img
          src={
            'https://res.cloudinary.com/drgz54gpg/w_600/' +
            image.imageid +
            '.jpg'
          }
          alt={image.nameLatin}
          className={`painting-image ${getImageSize()}`}
        />
        <MDBDropdown className='contract-card-dropdown-container'>
          <MDBDropdownToggle className='contract-card-dropdown'>
            <BsThreeDotsVertical />
          </MDBDropdownToggle>
          <MDBDropdownMenu style={{ zIndex: 100 }}>
            <Link
              className='contract-card-dropdown-link'
              to={'/edit-painting/' + image.id}
            >
              <MDBDropdownItem className='contract-card-dropdown-item'>
                <span>
                  <AiTwotoneEdit /> Edit
                </span>
              </MDBDropdownItem>
            </Link>
            <MDBDropdownItem
              className='contract-card-dropdown-item delete-dropdown-item'
              onClick={triggerContractDeletion}
            >
              <span>
                <BiSolidTrashAlt /> Delete
              </span>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </div>
      <div className='text-container'>
        <div className='text-wrapper'>
          <span className='card-header-text'>{image.nameLatin}</span>
        </div>
        <div className='text-wrapper'>
          <span className='card-cursive-text'>{image.name}</span>
        </div>
        <div className='text-wrapper'>
          <span className='card-normal-text'>{getCanvas()}</span>
        </div>
        <div>
          <span className='card-normal-text'>{getSize()}</span>
        </div>
      </div>
    </div>
  );
};

export default ExploreCard;
