import React, { useEffect, useState } from 'react';
import './ImageSelector.css';

import { MDBFileUpload } from 'mdb-react-file-upload';
import { MDBAlert } from 'mdb-react-ui-kit';

// Types
import { ImageStatus } from '../../../types/image/ImageStatus';

type Props = {
  existingImage: string;
  formData: FormData;
  imageText: string;
  className?: string;
  setFormData: (x: FormData) => void;
  setImageStatus?: (x: ImageStatus) => void;
  setImageText: (x: string) => void;
  setImageError: (x: string) => void;
};

const ImageSelector: React.FC<Props> = ({
  existingImage,
  formData,
  imageText,
  className,
  setFormData,
  setImageStatus,
  setImageText,
  setImageError,
}: Props) => {
  const [showFileTypeError, setShowFileTypeError] = useState(false);

  const getFile = (file: any) => {
    if (file.length > 0) {
      const fileSplit = file[0].name.split('.');
      const fileType = fileSplit[fileSplit.length - 1].toLowerCase();

      if (
        fileType === 'png' ||
        fileType === 'jpg' ||
        fileType === 'jpeg' ||
        fileType === 'svg'
      ) {
        const reader = new FileReader();
        reader.readAsText(file[0], 'utf-8');
        reader.onload = async (e: any) => {
          if (e.target.result !== imageText) {
            setImageText(e.target.result);

            formData.set('image', file[0]);
            setFormData(formData);

            if (setImageStatus) {
              console.log('herhe');
              setImageStatus(ImageStatus.NEW);
            }
            setShowFileTypeError(false);
            setImageError('');

            formatFileName();
          }
        };
      } else {
        setShowFileTypeError(true);
        setFormData(new FormData());
        setImageText('');
        if (setImageStatus) {
          setImageStatus(ImageStatus.EMPTY);
        }
      }
    }
  };

  const removeFile = () => {
    setShowFileTypeError(false);
    setFormData(new FormData());
    setImageText('');
    if (setImageStatus) {
      setImageStatus(ImageStatus.EMPTY);
    }
  };

  const formatFileName = () => {
    const filenameObj = document.querySelector('.file-upload-file-name');
    if (filenameObj) {
      let filename = filenameObj.innerHTML;

      if (filename.length > 13) {
        filename = filename.slice(0, 13) + '...';
      }

      filenameObj.innerHTML = filename;
    }
  };

  useEffect(() => {
    formatFileName();
  }, [existingImage]);

  return (
    <div className={`ImageSelector ${className}`}>
      <div className='uploader-container'>
        <MDBFileUpload
          className='uploader'
          getInputFiles={getFile}
          maxFileSize='10M'
          defaultFile={
            existingImage !== ''
              ? 'https://res.cloudinary.com/drgz54gpg/w_600/' +
                existingImage +
                '.jpg'
              : undefined
          }
          defaultMessage='Upload Image'
          onRemove={removeFile}
        />
      </div>
      <MDBAlert
        color='danger'
        show={showFileTypeError}
        className='wrong-file-alert'
      >
        <b>Incorrect file format</b>
        <br />
        <span>
          Please upload a file with the extension: .png, .jpg, .jpeg or .svg
        </span>
      </MDBAlert>
    </div>
  );
};

export default ImageSelector;
