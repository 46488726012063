import React from 'react';
import './LoadingScreen.css';
import { MDBSpinner } from 'mdb-react-ui-kit';

type Props = {
  isLoading: boolean;
};

const LoadingScreen: React.FC<Props> = ({ isLoading }: Props) => {
  return (
    <div
      className={`LoadingScreen ${isLoading ? 'show-creation-loading' : ''}`}
    >
      <MDBSpinner color='light' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </MDBSpinner>
    </div>
  );
};

export default LoadingScreen;
