import React from 'react';
import './ExploreMailCard.css';

// Libraries
import { Link } from 'react-router-dom';
import {
  MDBCard,
  MDBCardBody,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from 'mdb-react-ui-kit';

import { AiTwotoneEdit } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { IoIosSend } from 'react-icons/io';

// Types
import { ExploreMail } from '../../../../types/exploreMails/ExploreMail';

type Props = {
  mail: ExploreMail;
  className?: string;
  sendMail: (x: string) => void;
  deleteMail: (x: string) => Promise<boolean>;
};

const ExploreMailCard: React.FC<Props> = ({
  mail,
  className,
  sendMail,
  deleteMail,
}: Props) => {
  const triggerMailDeletion = async () => {
    await deleteMail(mail.id);
  };

  const triggerSendMail = () => {
    sendMail(mail.id);
  };

  return (
    <div className={`ExploreMailCard ${className}`}>
      <MDBCard>
        <MDBCardBody className='card-container'>
          <div className='mail-name'>
            <span>{mail.name}</span>
          </div>
          <MDBDropdown className='contract-card-dropdown-container'>
            <MDBDropdownToggle className='contract-card-dropdown'>
              <BsThreeDotsVertical />
            </MDBDropdownToggle>
            <MDBDropdownMenu style={{ zIndex: 100 }}>
              <MDBDropdownItem
                className='contract-card-dropdown-item'
                onClick={triggerSendMail}
              >
                <span>
                  <IoIosSend /> Send
                </span>
              </MDBDropdownItem>
              <Link
                className='contract-card-dropdown-link'
                to={'/edit-mail/' + mail.id}
              >
                <MDBDropdownItem className='contract-card-dropdown-item'>
                  <span>
                    <AiTwotoneEdit /> Edit
                  </span>
                </MDBDropdownItem>
              </Link>
              <MDBDropdownItem
                className='contract-card-dropdown-item delete-dropdown-item'
                onClick={triggerMailDeletion}
              >
                <span>
                  <BiSolidTrashAlt /> Delete
                </span>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default ExploreMailCard;
