import React from 'react';
import './Home.css';

import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

// Icons
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineMail } from 'react-icons/hi';
import { FaRegNewspaper } from 'react-icons/fa6';

const Home: React.FC = () => {
  return (
    <div className='Home'>
      <MDBRow className='margin-bottom-15'>
        <MDBCol md='6' className='top-col'>
          <Link to='/explore'>
            <MDBCard className='link-card'>
              <MDBCardBody>
                <div className='upper-card'>
                  <div className='grey-icon-background'>
                    <IoSearchOutline className='card-icon' />
                  </div>
                </div>
                <div className='lower-card'>
                  <b>Explore Paintings</b>
                </div>
              </MDBCardBody>
            </MDBCard>
          </Link>
        </MDBCol>
        <MDBCol md='6'>
          <Link to='/upload-painting'>
            <MDBCard className='link-card'>
              <MDBCardBody>
                <div className='upper-card'>
                  <div className='grey-icon-background'>
                    <AiOutlineCloudUpload className='card-icon' />
                  </div>
                </div>
                <div className='lower-card'>
                  <b>Add New Painting</b>
                </div>
              </MDBCardBody>
            </MDBCard>
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow className='margin-bottom-15'>
        <MDBCol md='6' className='top-col'>
          <Link to='/mail-explore'>
            <MDBCard className='link-card'>
              <MDBCardBody>
                <div className='upper-card'>
                  <div className='grey-icon-background'>
                    <HiOutlineMail className='card-icon' />
                  </div>
                </div>
                <div className='lower-card'>
                  <b>Emails</b>
                </div>
              </MDBCardBody>
            </MDBCard>
          </Link>
        </MDBCol>
        <MDBCol md='6'>
          <Link to='/newsletter-subscribers'>
            <MDBCard className='link-card'>
              <MDBCardBody>
                <div className='upper-card'>
                  <div className='grey-icon-background'>
                    <FaRegNewspaper className='card-icon' />
                  </div>
                </div>
                <div className='lower-card'>
                  <b>Newsletter Subscribers</b>
                </div>
              </MDBCardBody>
            </MDBCard>
          </Link>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default Home;
