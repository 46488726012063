import React, { useCallback, useEffect, useState } from 'react';
import './NewsletterUserInfo.css';
import { ApiService } from '../../services/Api-Service';
import SimpleLoadingScreen from '../../components/atoms/simpleLoadingScreen/SimpleLoadingScreen';
import CountUp from 'react-countup';

const NewsletterUserInfo: React.FC = () => {
  const [users, setUsers] = useState<number | null>(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getUsers = useCallback(async () => {
    const res: number | null = await ApiService.getNewsletterUser();
    if (res != null) {
      setUsers(res);
    }
    setIsPageLoading(false);
  }, []);

  useEffect(() => {
    if (users == null) {
      getUsers();
    } else {
      setIsPageLoading(false);
    }
  }, [users, getUsers]);
  return (
    <div className='NewsletterUserInfo'>
      {!isPageLoading ? (
        users != null ? (
          <div className='info-container'>
            <CountUp
              start={0}
              end={users}
              duration={1}
              className='count-number'
            />
            <span className='subscriber-text'>Newsletter Subscriber</span>
          </div>
        ) : (
          <div>Something went wrong.</div>
        )
      ) : (
        <SimpleLoadingScreen />
      )}
    </div>
  );
};

export default NewsletterUserInfo;
