import axios from 'axios';
import Cookies from 'universal-cookie';

// Types
import { LoginInput } from '../types/login/LoginInput';
import { LoginResult } from '../types/login/LoginResult';
import { InitialReauthenticationResult } from '../types/authentication/initialReauthenticationResult';
import { ReauthenticationResult } from '../types/authentication/ReauthenticationResult';
import { AddImageContentInput } from '../types/image/AddImageContentInput';
import { AddImageContentResult } from '../types/image/AddImageContentResult';
import { ExploreImagesResult } from '../types/explore/ExploreImagesResult';
import { EditImage } from '../types/image/EditImage';
import { EditImageContentInput } from '../types/image/EdiImageContentInput';
import { ExploreMailsResult } from '../types/exploreMails/ExploreMailsResult';
import { AddEmailTemplateInput } from '../types/emailTemplate/AddEmailTemplateInput';
import { EditEmailTemplate } from '../types/emailTemplate/EditEmailTemplate';

const baseUrl = 'https://majunkerart-backend.onrender.com';

export const ApiService = {
  async login(user: LoginInput) {
    try {
      const result = await axios.post(baseUrl + '/users/login', user);

      const data: LoginResult = result.data;

      const cookies = new Cookies();

      if (data.refreshToken !== '0' && data.refreshToken !== '1') {
        cookies.set('adminRefreshToken', data.refreshToken, { path: '/' });
      }

      if (data.accessToken !== '0' && data.accessToken !== '1') {
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + data.accessToken;
      }

      if (
        data.refreshToken !== '0' &&
        data.refreshToken !== '1' &&
        data.accessToken !== '0' &&
        data.accessToken !== '1'
      ) {
        const userString = JSON.stringify(data.user);
        cookies.set('adminUser', userString, { path: '/' });
      }

      return data.user;
    } catch (error) {
      return null;
    }
  },

  async initialReauthentication(tempToken: string) {
    try {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + tempToken;
      const result = await axios.post(
        baseUrl + '/users/initial-reauthentication'
      );

      const data: InitialReauthenticationResult = result.data;

      const cookies = new Cookies();

      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.accessToken;

      const userString = JSON.stringify(data.user);
      cookies.set('adminUser', userString, { path: '/' });

      return data.user;
    } catch (error) {
      const cookies = new Cookies();
      cookies.remove('adminRefreshToken', { path: '/' });
      cookies.remove('adminUser', { path: '/' });

      axios.defaults.headers.common['Authorization'] = '';
      return null;
    }
  },

  async reauthentication() {
    try {
      const cookies = new Cookies();
      const refreshToken = cookies.get('adminRefreshToken');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken;

      const result = await axios.post(baseUrl + '/users/reauthentication');

      const data: ReauthenticationResult = result.data;

      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.accessToken;

      return true;
    } catch (error) {
      console.log(error);
      const cookies = new Cookies();
      cookies.remove('adminRefreshToken', { path: '/' });
      cookies.remove('adminUser', { path: '/' });

      axios.defaults.headers.common['Authorization'] = '';
      return false;
    }
  },

  async uploadPaintingContent(this: any, content: AddImageContentInput) {
    try {
      const result = await axios.post(baseUrl + '/add-painting', content);

      const data: AddImageContentResult = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.uploadPaintingContent(content);
            return secondResult;
          }
        }
      } catch (error) {}
      return null;
    }
  },

  async uploadPaintingImage(this: any, id: string, image: FormData) {
    try {
      const result = await axios.post(baseUrl + '/add-painting/' + id, image, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.uploadPaintingImage(id, image);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async searchPaintings(this: any, search: string, page: number, sort: number) {
    try {
      const result = await axios.get(
        baseUrl +
          '/admin-explore?search=' +
          search +
          '&page=' +
          page +
          '&sort=' +
          sort
      );

      const data: ExploreImagesResult = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.searchPaintings(search, page, sort);
            return secondResult;
          }
        }
      } catch (error) {}
      return null;
    }
  },

  async getEditPainting(this: any, id: string) {
    try {
      const result = await axios.get(baseUrl + '/paintings/' + id);

      const data: EditImage = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.getEditPainting(id);
            return secondResult;
          }
        }
      } catch (error) {}
      return null;
    }
  },

  async updatePaintingContent(
    this: any,
    id: string,
    input: EditImageContentInput
  ) {
    try {
      const result = await axios.put(baseUrl + '/paintings/' + id, input);

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.updatePaintingContent(id, input);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async updatePaintingImage(this: any, id: string, image: FormData) {
    try {
      const result = await axios.put(
        baseUrl + '/paintings/' + id + '/image',
        image,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.updatePaintingImage(id, image);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async deletePainting(this: any, id: string) {
    try {
      const result = await axios.delete(baseUrl + '/paintings/' + id);

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.deletePainting(id);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async searchMails(this: any, search: string, page: number) {
    try {
      const result = await axios.get(
        baseUrl + '/mails?search=' + search + '&page=' + page
      );

      const data: ExploreMailsResult = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.searchMails(search, page);
            return secondResult;
          }
        }
      } catch (error) {}
      return null;
    }
  },

  async addEmailTemplate(this: any, input: AddEmailTemplateInput) {
    try {
      const result = await axios.post(baseUrl + '/mails', input);

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.addEmailTemplate(input);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async updateEmailTemplate(
    this: any,
    id: string,
    input: AddEmailTemplateInput
  ) {
    try {
      const result = await axios.put(baseUrl + '/mails/' + id, input);

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.updateEmailTemplate(id, input);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async getEditTemplate(this: any, id: string) {
    try {
      const result = await axios.get(baseUrl + '/mails/' + id);

      const data: EditEmailTemplate = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.getEditTemplate(id);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async deleteTemplate(this: any, id: string) {
    try {
      const result = await axios.delete(baseUrl + '/mails/' + id);

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.deleteTemplate(id);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async sendEmailTemplate(this: any, id: string) {
    try {
      const result = await axios.post(baseUrl + '/mails/' + id + '/send');

      const data: boolean = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.sendEmailTemplate(id);
            return secondResult;
          }
        }
      } catch (error) {}
      return false;
    }
  },

  async getNewsletterUser(this: any) {
    try {
      const result = await axios.get(baseUrl + '/newsletter-users');

      const data: number = result.data;

      return data;
    } catch (error: any) {
      try {
        if (error.response.status === 401) {
          let authRes: boolean = await this.reauthentication();
          if (authRes) {
            let secondResult = await this.getNewsletterUser();
            return secondResult;
          }
        }
      } catch (error) {}
      return null;
    }
  },
};
