import React, { useCallback, useEffect, useState } from 'react';
import './EditPainting.css';

// Services
import { ApiService } from '../../services/Api-Service';

// Types
import { EditImage } from '../../types/image/EditImage';
import { useParams } from 'react-router-dom';
import SimpleLoadingScreen from '../../components/atoms/simpleLoadingScreen/SimpleLoadingScreen';
import EditPaintingContent from '../../components/organisms/editPainting/editPaintingContent/EditPaintingContent';

type Props = {
  triggerPaintingSaved: () => void;
};

const EditPainting: React.FC<Props> = ({ triggerPaintingSaved }: Props) => {
  let { id } = useParams();
  const [painting, setPainting] = useState<EditImage | null>(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getPainting = useCallback(async () => {
    if (id) {
      const res: EditImage | null = await ApiService.getEditPainting(id);

      if (res) {
        console.log(res);
        setPainting(res);
      }

      setIsPageLoading(false);
    } else {
      setIsPageLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (painting == null) {
      getPainting();
    } else {
      setIsPageLoading(false);
    }
  }, [painting, getPainting]);

  return (
    <div className='EditPainting'>
      {!isPageLoading ? (
        painting != null ? (
          <EditPaintingContent
            painting={painting}
            triggerPaintingSaved={triggerPaintingSaved}
          />
        ) : (
          <span>Painting not found.</span>
        )
      ) : (
        <SimpleLoadingScreen />
      )}
    </div>
  );
};

export default EditPainting;
