import React from 'react';
import './Navigation.css';
import { Link } from 'react-router-dom';

const Navigation: React.FC = () => {
  return (
    <div className='Navigation'>
      <Link to=''>
        <span className='nav-title'>
          Martina Junker <span className='nav-title-art'>Art</span>
        </span>
      </Link>
    </div>
  );
};

export default Navigation;
