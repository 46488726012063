import React from 'react';
import './CanvasPicker.css';

import { MDBSelect } from 'mdb-react-ui-kit';
import { CanvasType } from '../../../types/image/CanvasType';

type Props = {
  className?: string;
  value: number;
  changeSelection: (x: CanvasType) => void;
};

const CanvasPicker: React.FC<Props> = ({
  className,
  value,
  changeSelection,
}: Props) => {
  const triggerChange = (e: any) => {
    changeSelection(e.value);
  };
  return (
    <div className={`CanvasPicker ${className}`}>
      <MDBSelect
        label='Canvas Type'
        data={[
          {
            text: 'Acrylic on canvas',
            value: CanvasType.ACRYLIC,
            defaultSelected: value === CanvasType.ACRYLIC,
          },
          {
            text: 'Acrylic + oil stick on canvas',
            value: CanvasType.ACRYLICANDOILSTICK,
            defaultSelected: value === CanvasType.ACRYLICANDOILSTICK,
          },
        ]}
        onValueChange={triggerChange}
      />
    </div>
  );
};

export default CanvasPicker;
