import React from 'react';
import './ColorSchemePicker.css';

import { MDBSelect } from 'mdb-react-ui-kit';
import { ColorScheme } from '../../../types/image/ColorScheme';

type Props = {
  className?: string;
  value: number;
  changeSelection: (x: ColorScheme) => void;
};

const ColorSchemePicker: React.FC<Props> = ({
  className,
  value,
  changeSelection,
}: Props) => {
  const triggerChange = (e: any) => {
    changeSelection(e.value);
  };
  return (
    <div className={`ColorSchemePicker ${className}`}>
      <MDBSelect
        label='Color Scheme'
        data={[
          {
            text: 'None',
            value: ColorScheme.NONE,
            defaultSelected: value === ColorScheme.NONE,
          },
          {
            text: 'Blueish',
            value: ColorScheme.BLUE,
            defaultSelected: value === ColorScheme.BLUE,
          },
          {
            text: 'Greenish',
            value: ColorScheme.GREEN,
            defaultSelected: value === ColorScheme.GREEN,
          },
          {
            text: 'Beigeish',
            value: ColorScheme.BEIGE,
            defaultSelected: value === ColorScheme.BEIGE,
          },
          {
            text: 'Yellowish',
            value: ColorScheme.YELLOW,
            defaultSelected: value === ColorScheme.YELLOW,
          },
          {
            text: 'Purplish',
            value: ColorScheme.PURPLE,
            defaultSelected: value === ColorScheme.PURPLE,
          },
          {
            text: 'Reddish',
            value: ColorScheme.RED,
            defaultSelected: value === ColorScheme.RED,
          },
          {
            text: 'Pinkish',
            value: ColorScheme.PINK,
            defaultSelected: value === ColorScheme.PINK,
          },
        ]}
        onValueChange={triggerChange}
      />
    </div>
  );
};

export default ColorSchemePicker;
