import React, { useCallback, useEffect, useState } from 'react';
import './ExploreMails.css';

// Libraries
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { MDBRow, MDBCol, MDBBtn, MDBAlert } from 'mdb-react-ui-kit';

// Components
import SearchBar from '../../components/atoms/searchBar/SearchBar';
import Paging from '../../components/atoms/paging/Paging';

// Services
import { ApiService } from '../../services/Api-Service';

// Types
import SimpleLoadingScreen from '../../components/atoms/simpleLoadingScreen/SimpleLoadingScreen';
import LoadingScreen from '../../components/atoms/loadingScreen/LoadingScreen';
import { ExploreMail } from '../../types/exploreMails/ExploreMail';
import { ExploreMailsResult } from '../../types/exploreMails/ExploreMailsResult';
import ExploreMailCard from '../../components/organisms/exploreMails/exploreMailCard/ExploreMailCard';

const ExploreMails: React.FC = () => {
  //   let { urlSearch, urlPage } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);

  const [pagesCount, setPagesCount] = useState(1);
  const [mails, setMails] = useState<ExploreMail[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState(true);

  const [showDeletionSuccess, setShowDeletionSuccess] = useState(false);
  const [showDeletionError, setShowDeletionError] = useState(false);

  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showWrongError, setShowWrongError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const searchMails = useCallback(
    async (searchString: string, page: number) => {
      let encodedString = encodeURI(searchString);
      setIsSearchLoading(true);
      const res: ExploreMailsResult | null = await ApiService.searchMails(
        encodedString,
        page
      );

      if (res != null) {
        setMails(res.mails);
        setSelectedPage(res.page);
        setPagesCount(res.pages);
      }

      setIsSearchLoading(false);
    },
    []
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  useEffect(() => {
    // get params
    let urlSearch = searchParams.get('search');
    let urlPage = searchParams.get('page');

    // Convert value or set as undefined
    let urlCheckedSearch: string | undefined = urlSearch
      ? urlSearch
      : undefined;
    let urlCheckedPage: number | undefined =
      urlPage != null && parseInt(urlPage) ? parseInt(urlPage) : undefined;

    // set selected States
    setSelectedSearch(urlCheckedSearch ? urlCheckedSearch : '');
    setSearch(urlCheckedSearch ? urlCheckedSearch : '');
    setSelectedPage(urlCheckedPage ? urlCheckedPage : 1);

    searchMails(
      urlCheckedSearch ? urlCheckedSearch : '',
      urlCheckedPage ? urlCheckedPage : 1
    );
  }, [searchParams, navigate, searchMails]);

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const changeSearch = (newSearch: string) => {
    setSelectedSearch(newSearch);

    // fix url
    navigate(
      '/mail-explore' +
        (newSearch !== '' || selectedPage !== 1 ? '?' : '') +
        (newSearch !== '' ? 'search=' + newSearch : '') +
        (newSearch !== '' && selectedPage !== 1 ? '&' : '') +
        (selectedPage !== 1 ? 'page=' + selectedPage : '')
    );
  };

  const changePage = (newPage: number) => {
    // if (newPage !== selectedPage) {
    setSelectedPage(newPage);

    // fix url
    navigate(
      '/mail-explore' +
        (selectedSearch !== '' || newPage !== 1 ? '?' : '') +
        (selectedSearch !== '' ? 'search=' + selectedSearch : '') +
        (selectedSearch !== '' && newPage !== 1 ? '&' : '') +
        (newPage !== 1 ? 'page=' + newPage : '')
    );
    // }
  };

  const clear = () => {
    navigate('/explore');
  };

  const deleteMail = async (id: string) => {
    setIsLoading(true);
    let res: boolean = await ApiService.deleteTemplate(id);
    setIsLoading(false);
    if (res) {
      triggerDeletionSuccessAlert();
      searchMails(selectedSearch, selectedPage);
      return true;
    } else {
      triggerDeletionErrorAlert();
      return false;
    }
  };

  const sendMail = async (id: string) => {
    setIsLoading(true);
    const res: boolean = await ApiService.sendEmailTemplate(id);
    setIsLoading(false);
    if (res) {
      triggerEmailSuccessAlert();
    } else {
      triggerWrongErrorAlert();
    }
  };

  const triggerDeletionSuccessAlert = () => {
    setShowDeletionSuccess(true);
    setTimeout(() => {
      setShowDeletionSuccess(false);
    }, 3000);
  };

  const triggerDeletionErrorAlert = () => {
    setShowDeletionError(true);
    setTimeout(() => {
      setShowDeletionError(false);
    }, 3000);
  };

  const triggerEmailSuccessAlert = () => {
    setShowEmailSuccess(true);
    setTimeout(() => {
      setShowEmailSuccess(false);
    }, 3000);
  };

  const triggerWrongErrorAlert = () => {
    setShowWrongError(true);
    setTimeout(() => {
      setShowWrongError(false);
    }, 3000);
  };

  return (
    <div className='ExploreMails'>
      <SearchBar
        value={search}
        label='Search'
        onChange={onSearchChange}
        triggerSearch={changeSearch}
      />
      <MDBRow className='sort-container'>
        <MDBCol md='6' className='clear-container'>
          <MDBBtn onClick={clear}>Clear</MDBBtn>
        </MDBCol>
        <MDBCol md='6' className='add-container'>
          <Link to='/create-mail'>
            <MDBBtn onClick={clear}>Add Email Template</MDBBtn>
          </Link>
        </MDBCol>
      </MDBRow>
      {!isSearchLoading ? (
        mails.length !== 0 ? (
          mails.map((tempMail: ExploreMail) => (
            <div key={tempMail.id} className='mail-card'>
              <ExploreMailCard
                mail={tempMail}
                sendMail={sendMail}
                deleteMail={deleteMail}
              />
            </div>
          ))
        ) : (
          <div className='no-contracts-container'>
            <span>No mails</span>
          </div>
        )
      ) : (
        <SimpleLoadingScreen />
      )}

      {mails.length !== 0 ? (
        <Paging
          pagesCount={pagesCount}
          selectedPage={selectedPage}
          className='paging-input'
          changePage={changePage}
        />
      ) : null}
      <LoadingScreen isLoading={isLoading} />
      <MDBAlert
        color='success'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showDeletionSuccess}
        // className='custom-alert-center-position'
      >
        <b>Mail deleted!</b>
      </MDBAlert>
      <MDBAlert
        color='danger'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showDeletionError}
        // className='custom-alert-center-position'
      >
        <i className='fas fa-times-circle me-3'></i>Deletion failed.
      </MDBAlert>
      <MDBAlert
        color='success'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showEmailSuccess}
      >
        <b>Mail send!</b>
      </MDBAlert>
      <MDBAlert
        color='danger'
        autohide
        position='top-center'
        offset={100}
        delay={3000}
        appendToBody
        show={showWrongError}
      >
        <i className='fas fa-times-circle me-3'></i>Somehting went wrong.
      </MDBAlert>
    </div>
  );
};

export default ExploreMails;
