import React from 'react';
import './SizePicker.css';

import { MDBSelect } from 'mdb-react-ui-kit';
import { SizeType } from '../../../types/image/SizeType';

type Props = {
  className?: string;
  value: number;
  changeSelection: (x: SizeType) => void;
};

const SizePicker: React.FC<Props> = ({
  className,
  value,
  changeSelection,
}: Props) => {
  const triggerChange = (e: any) => {
    changeSelection(e.value);
  };
  return (
    <div className={`SizePicker ${className}`}>
      <MDBSelect
        label='Size'
        data={[
          {
            text: '15x15x1.5',
            value: SizeType.FIFTEEN,
            defaultSelected: value === SizeType.FIFTEEN,
          },
          {
            text: '30x30x4.5',
            value: SizeType.THIRTY,
            defaultSelected: value === SizeType.THIRTY,
          },
          {
            text: '40x40x4.5',
            value: SizeType.FOURTY,
            defaultSelected: value === SizeType.FOURTY,
          },
          {
            text: '50x50x4.5',
            value: SizeType.FIFTY,
            defaultSelected: value === SizeType.FIFTY,
          },
          {
            text: '60x60x4.5',
            value: SizeType.SIXTY,
            defaultSelected: value === SizeType.SIXTY,
          },
          {
            text: '80x80x4.5',
            value: SizeType.EIGHTY,
            defaultSelected: value === SizeType.EIGHTY,
          },
        ]}
        onValueChange={triggerChange}
      />
    </div>
  );
};

export default SizePicker;
